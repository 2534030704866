/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  env: {
    STAGE: "prod",
    PRODUCT: "tax",
    DOMAIN: "prod.canei.io",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://es67jiyrg2.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://es67jiyrg2.execute-api.eu-central-1.amazonaws.com/prod/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_3isqg3y57",
    APP_CLIENT_ID: "78j32hrbpuq06ecpjurpliabkn",
    IDENTITY_POOL_ID: "eu-central-1:77e71ad2-df23-48af-98ca-99fe4d1e8e65",
  },
  i18n: {
    host: "locales.digital.prod.canei.io",
  },
  assets: {
    host: "assets.digital.prod.canei.io",
  },
  registration: {
    FORM_POST: "https://support.digital.prod.canei.io/v1/forms",
    NEXT_PAGE: "https://app.tax.prod.canei.io",
    TO_REFERRER: "https://canei.tax/register-success/",
  },
  payment: {
    publicKey: "pk_live_zkYorNOrxbzoHdtaQm8yDWd5",
  },
};

export default awsConfig;
